import styled from 'styled-components';
import { baseCaption, defaultFont } from '../../../theme/typography';
import { green300, black600 } from '../../../theme/colors';
import themeSizes from "../../../theme/themeSizes";

export const RightContainerStyle = styled.section`
  position: relative;
  > img {
    width: 180px;
    height: 50px;
    display: block;
    margin: 64px 64px 24px;
    
    @media ${themeSizes.mobile} {
      margin: 40px auto;
    }
  }
`;

export const PageDescriptionStyle = styled.div`
  padding: 140px 64px 108px;
  height: 100vh;
  width: 100%;

  position: absolute;
  top: 0;
  
  @media ${themeSizes.mobile}{
    position: relative;
    padding: 70px 42px 140px;
    height: auto
  }
`;

export const HightLightText = styled.p`
  ${baseCaption}
  color: ${green300};
  margin-bottom: 8px;
`;

export const FormStyle = styled.form`
  width: 418px;
  padding-bottom: ${(props)=>props.check === "ekyc" ?"0px" : "58px"};
  button{
    display:block;
    margin-top:55px;
  }
  
  @media ${themeSizes.mobile}{
    width: 100%
  }
`;

export const FormSubElementStyle = styled.p`
  ${defaultFont}
  color: ${black600};
  text-align: right;
  margin-top:16px;
  span{
    cursor: pointer;
  }
`;
