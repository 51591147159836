import React, { useState } from 'react';
import BulkUpload from '../BulkUpload';
import get from '../../../helpers/lodashUtils/get';
import { GET_HELP_FILE } from '../../../helpers/constants/apiUrl';
import bulkUploadData from '../../../actions/creation/bulkUploadData';
import activateBulkUploadData from '../../../actions/creation/activateBulkUploadData';
import ROUTES, { DASHBOARDROUTES } from '../../../helpers/constants/routes';
import { Fragment } from 'react';
import BulkTable from '../BulkTable';
import IciciBulkTable from "../../icici-verfication/iciciBulkTable";

const BulkUploadWrapper = (props) => {
    const {
        history, location, headerText = 'Upload', fileAllowedText = '',
        downloadParam = '', processAction = () => { }, enableSuccessAlert = true, uploadSuccessText = null, backToListText = '',
        customElement,
        activationExtraKey,
        match, showFileInput,
        bulkUploadType,
        callback,
        question_group = '',
        isSKU = false,
        isIcici = false
    } = props;

    const TableComponent = isIcici ?  IciciBulkTable : BulkTable
    let type = get(location, 'state.bulkUploadType') || bulkUploadType || 'users';
    const downloadPath = `${GET_HELP_FILE}?object=${type}${downloadParam}`;
    const backToList = () => {
        let Url = `${DASHBOARDROUTES.ORGANIZATION_USER_LIST}`;
        let historyState = {}
        switch (type) {
            case 'regions':
                Url = `${DASHBOARDROUTES.ORGANIZATION_ORG_VIEW_WITHOUT_KEY}${location.state.slug}/list-of-regions`;
                break;
            case 'cities':
                Url = `${DASHBOARDROUTES.META_CITY_LIST}`;
                break;
            case 'zones':
                Url = `${DASHBOARDROUTES.ORGANIZATION_ORG_VIEW_WITHOUT_KEY}${location.state.slug}/list-of-zones`;
                break;
            case 'territories':
                Url = `${DASHBOARDROUTES.ORGANIZATION_ORG_VIEW_WITHOUT_KEY}${location.state.slug}/list-of-territories`;
                break;
            case 'workflow_off_take_cycle_init':
                Url = location.pathname.replace('/bulk-upload', '');
                historyState = { ...location.state }
                break;
            case 'workflow_payout_plotting':
                Url = location.pathname.replace('/plot-payout', '');
                historyState = { ...location.state }
                break;
            case 'utility_whatsapp_messaging':
                Url = DASHBOARDROUTES.MESSAGING
                break;
            case 'workflow_kyc_spoc_upload':
            case 'workflow_payout_data_ingested':
            case 'workflow_payout_payment_status':
                Url = `${ROUTES.DASHBOARD}`;
                break;
            case 'questions':
                Url = `${DASHBOARDROUTES.SUB_PROJECTS_VIEW_WITHOUT_KEY({ projectSlug: match.params.projectSlug })}/${location.state.slug}${ROUTES.QUESTION_MANAGEMENT}`;
                historyState = { ...location.state }
                break;
            case 'panels':
                Url = `${DASHBOARDROUTES.SUB_PROJECTS_VIEW_WITHOUT_KEY({ projectSlug: match.params.projectSlug })}/${match.params.slug}/panel-management`;
                historyState = { ...location.state }
                break;
            default:
                break;
        }

        history.push(Url, historyState);
    }
    const isTableExist = type.indexOf('project_outlet_master') > -1;
    const [uploadedId, setUploadedId] = useState(null);
    const callBackUpload = (id) => {
        setUploadedId(id)
    }
    return (
        <Fragment>
            <BulkUpload
                objectType={type}
                downloadPath={downloadPath}
                uploadAction={bulkUploadData}
                downloadParam={downloadParam}
                activateAction={activateBulkUploadData}
                backToList={backToList}
                backToListText={backToListText}
                headerText={headerText}
                enableSuccessAlert={enableSuccessAlert}
                processAction={processAction}
                uploadSuccessText={uploadSuccessText}
                fileAllowedText={fileAllowedText}
                customElement={customElement}
                activationExtraKey={activationExtraKey}
                showFileInput={showFileInput}
                callback={callback}
                disableSuccess={!isTableExist}
                setUpload={callBackUpload}
            />
            {!isTableExist ? <TableComponent
                keyValue={type}
                refreshData={enableSuccessAlert}
                uploadedId={uploadedId}
                isSKU={isSKU}
                question_group={question_group}
            /> : null }
        </Fragment>
    )
}

export default BulkUploadWrapper
