import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactImageMagnify from "react-image-magnify";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import ImageIcon from "@material-ui/icons/Image";
import ImageGallery from "./image_gallery/ImageGallery";
import { getDayOrNumberSuffix } from "../helpers/getDayOrNumbersuffix";
import {size} from "lodash/collection";
import {DASHBOARDROUTES} from "../helpers/constants/routes";
import {useHistory} from "react-router-dom";

const ImageZoomWrapper = styled.div`
  display: flex;
  width: ${(props) => props.imageSectionwidth || "570px"};
  min-height:${(props) => props.noMinHeight ?'0px' : '500px'} ;
  justify-content: space-between;
  padding: 10px;

  .magnifier {
    width: 100% !important;
    margin: 10% auto 0;
  }
`;

const PreviewSection = styled.div`
  position: relative;
  width: 300px;
  height: 240px;
  margin-bottom: 30px;
`

const SingleImageSection = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    position: relative;
    bottom: 0;
    margin-top: 10px;
    text-align: center;
    width: 100%;

    svg:nth-of-type(3) {
      margin-left: 4px;
    }
  }
`;

const GalleryWrapper = styled.div`
  width: ${(props) => (props.imageSectionwidth ? "100%" : "40%")};
`;

const TotalImageCount = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #4770c1;
  color: #ffffff;
  padding: ${props => props.clientScreen ? '6px 12px' : '9px 12px'};
  font-weight: 500;
  font-size:${props => props.clientScreen ? '13px':  '14px'};
  margin-bottom: 30px;
  cursor: pointer;
`;

const ImageListWrapper = styled.div`
  margin-bottom: 30px;
  span,
  button{
    color: #ffffff;
    font-size: 14px;
    padding: 8px 12px;
    display: block;
    background: #3e96c8;
    width: max-content;
    margin: 0 auto 16px;
    border-radius: 2px;
    cursor: pointer;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: scroll;
    max-height: 120px;
    img {
      width: 30%;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
`;

let initial = true;
function resetOrientation(src, srcOrientation, callback) {
	var img = new Image();
  img.crossOrigin = "anonymous";

	img.onload = function() {
  	var width = img.width,
    		height = img.height,
        canvas = document.createElement('canvas'),
	  		ctx = canvas.getContext("2d");

    // set proper canvas dimensions before transform & export
		if (4 < srcOrientation && srcOrientation < 9) {
    	canvas.width = height;
      canvas.height = width;
    } else {
    	canvas.width = width;
      canvas.height = height;
    }

  	// transform context before drawing image
		switch (srcOrientation) {
      case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
      case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
      case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
      case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
      case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
      case 7: ctx.transform(0, -1, -1, 0, height , width); break;
      case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
      default: break;
    }

		// draw image
    ctx.drawImage(img, 0, 0);

    // // Append to the DOM
    // document.getElementById('root').appendChild(canvas);

		// export base64
		callback(canvas.toDataURL());
  };

	if(initial === true){
	  initial = false
	  return img.src = src + `?${Math.random()}`
  }
	img.src = src;
}

const ImageListComponent = ({
  title = "Test title",
  images = [
    "https://i.imgur.com/H9utx9q.jpg",
    "https://pyt-images.imgix.net/images/web_app/homepage/banners/2.jpg",
    "https://i.imgur.com/H9utx9q.jpg",
    "https://pyt-images.imgix.net/images/web_app/homepage/banners/2.jpg",
    "https://i.imgur.com/H9utx9q.jpg",
    "https://pyt-images.imgix.net/images/web_app/homepage/banners/2.jpg",
  ],
  onClickAction,
  onClickHandlerForButton
}) => {
  return (
    <ImageListWrapper>
      <button onClick={onClickHandlerForButton}>{title}</button>
      <div>
        {images.map((ele, index) => (
          <img
            onClick={() => onClickAction(ele)}
            alt={"surveyImg"}
            key={index}
            src={ele}
          />
        ))}
      </div>
    </ImageListWrapper>
  );
};

const ImageZoom = ({
  showCurrentSurveyImageTiles = true,
  showPrevSurveyImageTiles = false,
  prevAnsImages = {},
  totalImages = [],
  totalImageCount = 0,
  defaultActiveImage = "",
  currentSurveyImages = [],
  imageSectionwidth = "",
  newTab = false,
  noMinHeight,
  clientScreen,
  isSkuGroup = false,
  outlet_slug = null,
  outlet_id = null,
 workflowId,
 workflowSlug,
 prevWorkflowId
}) => {
  const [activeImage, setActiveImage] = useState("");
  const [currentOrientation, setCurrentOrientation] = useState(1);
  const [showGallery, setShowGallery] = useState(false);
  const [galleryImage, setGallerImage] = useState([]);
  const [noExit, setNoExit] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setActiveImage(defaultActiveImage);
  }, [defaultActiveImage]);

  useEffect(() => {
    if(window.location.search === "?modal=open"){
      setNoExit(true)
      setGallerImage(totalImages);
      setShowGallery(true);
    }
  }, [])

  const newTabOpen = (newTab) => {
    if(newTab){
      // To prevent attribute being added when clicked multiple times
      if(window.location.search === "?modal=open"){
        window.open(`${window.location.href}`,"_blank")
      }
      window.open(`${window.location.href}?modal=open`,"_blank")
    } else {
      setGallerImage(totalImages);
      setShowGallery(true)
    }
  }
  const rotateImage = (antiClockWise = false) => {
    let targetOrientation = currentOrientation;

    if(antiClockWise) {
      switch (currentOrientation) {
        case 1: targetOrientation = 8; break;
        case 8: targetOrientation = 3; break;
        case 3: targetOrientation = 6; break;
        case 6: targetOrientation = 1; break;
        default:
          break;
      }
    } else {
      switch (currentOrientation) {
        case 1: targetOrientation = 6; break;
        case 6: targetOrientation = 3; break;
        case 3: targetOrientation = 8; break;
        case 8: targetOrientation = 1; break;
        default:
          break;
      }
    }

    // setCurrentOrientation(targetOrientation);
    resetOrientation(activeImage, targetOrientation, newImage => setActiveImage(newImage));
  }

  return (
    <ImageZoomWrapper noMinHeight={noMinHeight} imageSectionwidth={imageSectionwidth}>
      {(currentSurveyImages.length || showPrevSurveyImageTiles) && (!isSkuGroup && activeImage) ? (
        <SingleImageSection>
          <PreviewSection id="preview" />
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "Wristwatch by Ted Baker London",
                src: activeImage,
                isFluidWidth: true
              },
              largeImage: {
                src: activeImage,
                width: 1200,
                height: 1200,
              },
              enlargedImageContainerDimensions: {
                width: '100%',
                height: '100%'
              },
              shouldUsePositiveSpaceLens: true,
              lensStyle: {
                width: '64px',
                height: '64px'
              },
            }}
            enlargedImagePortalId={"preview"}
            enlargedImageContainerStyle={{
              height: "240px"
            }}
          />
          <span>
            <RotateLeftIcon onClick={() => rotateImage(true)} />
            <RotateRightIcon onClick={() => rotateImage(false)} />
            <OpenWithIcon
              onClick={() => {
                setGallerImage([activeImage]);
                setShowGallery(true);
              }}
            />
          </span>
        </SingleImageSection>
      ) : null}
      <GalleryWrapper imageSectionwidth={imageSectionwidth}>
        {/*{totalImages.length > 0 ? (*/}
        {/*  <TotalImageCount*/}
        {/*    onClick={() => {*/}
        {/*      // setGallerImage(totalImages);*/}
        {/*      // setShowGallery(true);*/}
        {/*      newTabOpen(newTab)*/}
        {/*    }}*/}
        {/*    clientScreen={clientScreen}*/}
        {/*  >*/}
        {/*    <ImageIcon style={{*/}
        {/*      width: clientScreen && "10%"*/}
        {/*    }} /> {!clientScreen && totalImages.length} Total{" "}*/}
        {/*    {totalImages.length > 1 ? "Images" : "Image"}*/}
        {/*  </TotalImageCount>*/}
        {/*) : null}*/}
        <TotalImageCount
          onClick={() => {
            window.open(DASHBOARDROUTES.GET_DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES(outlet_slug, outlet_id, 'total-images'))
            // history.push(DASHBOARDROUTES.GET_DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES(outlet_slug, outlet_id))
          }}
          clientScreen={false}
        >
          <ImageIcon style={{
            width: false && "10%"
          }} /> {false && totalImages.length} Total{" "}
          {totalImages.length > 1 ? "Images" : "Image"}
        </TotalImageCount>
        {showCurrentSurveyImageTiles ? (
          <ImageListComponent
            onClickAction={setActiveImage}
            title="Current Survey Image"
            images={currentSurveyImages}
            onClickHandlerForButton={() => {
              window.open(DASHBOARDROUTES.GET_DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES(outlet_slug, workflowId, `current-survey-images-${workflowSlug}`))
            }}
          />
        ) : null}
        {showPrevSurveyImageTiles ?
          Object.keys(prevAnsImages).map(key => {
            return <ImageListComponent
              onClickAction={setActiveImage}
              images={prevAnsImages[key]}
              onClickHandlerForButton={() => {
                // prevWorkflowId
                window.open(DASHBOARDROUTES.GET_DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES(outlet_slug, prevWorkflowId, 'prev-survey-images'))
              }}
              title={`${getDayOrNumberSuffix(key)} Prev Survey Images`}
            />
          })
        : null}
        {showGallery ? (
          <ImageGallery
            images={ galleryImage.length > 0 ? galleryImage :  totalImages}
            setShowGallery={setShowGallery}
            noExit={noExit}
          />
        ) : null}
      </GalleryWrapper>
    </ImageZoomWrapper>
  );
};

export default ImageZoom;
