import React, { useState, Fragment } from "react";
import { withLastLocation } from "react-router-last-location";
import { FormContainer } from "./components/FormContainer";
import { Upload } from "../../components/Dasboard_Components/Upload";
import { ButtonContainer, Button } from "./dashboardLayoutStyle";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import styled, { css } from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";

import { defaultFont } from "../../theme/typography";
import {
  green400,
  green100,
  white,
  curiousBlue300,
  cinnabar100,
  cinnabar400,
  blue300
} from "../../theme/colors";
import { FormRow } from "./components/FormRow";
import FileUpload from "../../components/FileUpload";
import AlertBox from "../../components/ui/AlertBox";

export const Anchor = styled.a`
  border: 1px solid #212323;
  border-radius: 2px;
  background: ${(props) => props.background || "#212323"};
  color: ${(props) => props.color || "#FFFFFF"};
  font-size: 16px;
  font-weight: 300;
  padding: 10px;
  margin-left: ${({ align }) => align === "right" && `12px`};
  margin-right: ${({ align }) => align === "left" && `12px`};

  svg {
    vertical-align: -7px;
  }
`;

const ButtonWrapper = styled(Button)`
  position: relative;

  input[type="file"] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
  ${props => props.isDisabled && css`
        pointer-events:none;
        opacity: 0.8;
  `}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
 >a {
    color: ${blue300};
    text-decoration: underline;
  }
`;

const SideElement = ({ path, history, children }) => {
  return (
    <ButtonContainer>
      {children}
      <Anchor
        href={path}
        target="_blank"
        background={curiousBlue300}
        color={white}
      >
        <MoveToInboxIcon style={{ color: white }} />
        {"   "}Download Sample File
      </Anchor>
    </ButtonContainer>
  );
};

const BulkUpload = ({
  callback,
  uploadAction,
  downloadParam,
  downloadPath,
  activateAction,
  objectType = "Ready Reckoner Upload",
  backToList = () => {},
  backToListText = "",
  history,
  headerText = "Upload",
  processAction = () => {},
  enableSuccessAlert = true,
  uploadSuccessText = null,
  fileAllowedText='',
  customElement,
  activationExtraKey,
  showFileInput,
  disableSuccess = false,
  setUpload
}) => {
  const [showSuccess, setSuccess] = useState(false);
  const [showActivated, setActivated] = useState(false);
  const [showFailed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [mergeClicked, setMergeClicked] = useState(false)
  const [fileUploadedId, setFileUploaded] = useState(null);

  const showError = () => {
    if (errorMessage.detail && errorMessage.detail.length) {
      return <p>{errorMessage.detail}</p>
    }else if (errorMessage.file && errorMessage.file.length) {
      return <p>{errorMessage.file}</p>
    } else {
      const errorKeys = Object.keys(errorMessage);
      const filteredErrors = errorKeys.filter((e) => e > 0);

      return filteredErrors.map((item, inx) => {
        const tgtObj = errorMessage[item];
        const keys = Object.keys(tgtObj);

        return (
          <div>
            <b>Found errors in the Row No: {item}</b>
            <ul>
              {keys.map((i) => {
                if (Array.isArray(tgtObj[i]) || typeof tgtObj[i] === "string") {
                return <li>{i ? i.replace('_',' '): ''} - {tgtObj[i]}</li>;
                } else {
                  return (
                    <li>
                      {i ? i.replace('_',' ') : ''}
                    <ul>
                      {Object.keys(tgtObj[i] || {}).map((data) => {
                        return <li>{data} - {tgtObj[i][data]}</li>;
                      })}
                    </ul>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        );
      });
    }
  };

  const uploadFile = (data) => {
    setLoading(true);
    const sub_project = (history.location.state || {}).subProjectId ? `&sub_project=${(history.location.state || {}).subProjectId}` : "";
    const downloadParams = downloadParam ?  downloadParam + sub_project : sub_project;
  

    uploadAction(data, objectType, downloadParams)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setFailed(false);
        setFileUploaded(res.id);
        processAction(true, res.id);
        setUpload && setUpload(res.id)
      })
      .catch((e) => {
        setLoading(false);
        setSuccess(false);
        setFailed(true);
        setErrorMessage(e);
        processAction(false, e);
      });
  };

  const fileUpload = (files) => {
    let file = files[0];
    if (file) {
      let imageData = new FormData();
      imageData.append("file", file);

      uploadFile(imageData);
    }
  };
const params = (history.location && (history.location.state || {}).subProjectId) ?`&sub_project=${(history.location.state || {}).subProjectId}` : ""

  return (
    <Fragment>
      {  showFileInput ?
        <FormContainer
        padding="0px"
        title={"Bulk WhatsApp Message"}
        className="text"
        footerLocation={"50%"}
        onClickAction={() => {
          uploadFile(file.fileData)}}
        isLoading={showLoading}
        onCancelAction={() => {setFile({})}}
        isClickDisabled={showLoading}
        actionButtonText={"Upload"}
      >
        <Row>
          <span>Upload Data for Bulk WhatsApp Message </span>
          <a href = {downloadPath}
             target = '_blank'
          >
            Download Template
          </a>
        </Row>
        <FormRow
          leftSideWidth={"0"}
          rightElement={
            <FileUpload
              boxWidth={"512px"}
              type="file"
              inputLabel="No File Choosen"
              customValue={file.fileName || " "}
              customFunction = { (formData,fileName) => { setFile({fileData:formData,fileName}) } }
            >
              Choose File
            </FileUpload>
          }
        />
      </FormContainer>
     :
      <FormContainer
        title={headerText}
        className="text"
        footerLocation={"57%"}
        actionButtons={false}
        rightSideTitleElement={
          <Fragment>
            <SideElement path={downloadPath+params} history={history}>
              {customElement && customElement()}
            </SideElement>
          </Fragment>

        }
      >
        <Upload onGetImageData={uploadFile} isLoading={showLoading} fileAllowedText={fileAllowedText}/>
      </FormContainer>
}
      {showSuccess || showFailed ? (
        <AlertBox isFailed={showFailed} boxWidth={showFileInput ? "auto" : ""}>
          {showSuccess ? (
            <p>
              {uploadSuccessText
                ? uploadSuccessText
                : showActivated
                ? "Completed Upload Successfully"
                : "Completed Processing Successfully"}
            </p>
          ) : (
            showError()
          )}
          <ButtonContainer style={mergeClicked?{cursor:'not-allowed'}:{}}>
            {showFailed ? (
              <ButtonWrapper as="button" background={cinnabar400} color={white}>
                <CachedIcon style={{ color: white }} />
                Reupload
                <input
                  type="file"
                  // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => {
                    //  e && e.preventDefault();

                    fileUpload(e.target.files);
                  }}
                  onClick={(event)=> {
                    event.currentTarget.value = null
               }}
                />
              </ButtonWrapper>
            ) : enableSuccessAlert && !disableSuccess ? (
              <ButtonWrapper
                onClick={(e) => {
                  e && e.preventDefault();
                  if(showActivated){
                    backToList()
                    showFileInput &&  setFile({})
                    showFileInput && setSuccess(false)
                  }else{
                    setMergeClicked(true);
                    const sub_project = (history.location.state || {}).subProjectId ? `&sub_project=${(history.location.state || {}).subProjectId}` : "";
                    const downloadParams = activationExtraKey ?  activationExtraKey + sub_project : sub_project;

                    const params = downloadParams;
                    activateAction(fileUploadedId, objectType,params)
                        .then(() => {
                          setActivated(true);
                          setMergeClicked(false)
                          callback();
                        })
                        .catch(() => {});
                  }
                }}
                background={green400}
                color={white}
                isDisabled={mergeClicked}
              >
                <CheckIcon style={{ color: white }} />
                {"  "}
                {showActivated
                  ? backToListText || "Go back to View All"
                  : "Merge With List"}
              </ButtonWrapper>
            ) : null}
          </ButtonContainer>
        </AlertBox>
      ) : null}
    </Fragment>
  );
};

export default withLastLocation(BulkUpload);
