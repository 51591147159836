import React, { useState,useEffect } from "react";
import {
  PageDescriptionStyle,
  FormStyle,
} from "../../login/components/RightSideContainer";
import { SectionTitle } from "../../login/components/SectionTitle";
import { TextBox } from "../../../components/TextBox";
import { Button } from "../../../components/Button";
import { EKYC_LOGIN_TITLE } from "./constant";
import { useHistory } from "react-router-dom";
import ROUTES, { EKYC } from "../../../helpers/constants/routes";
import LoginAction from "../../../actions/ekyc/login/Login";
import OtpSentAction from "../../../actions/ekyc/login/otpSent";
import useStore from "../../../appZustand/store"
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import { LOGOUT_ALL_SESSION } from '../../../helpers/constants/apiUrl';

const EkycLogin = () => {
  const {fetchMobileNo}=useStore((state)=>state)
  const history = useHistory();
  const [data, setData] = useState({
    mobileNumber: "",
    isError: false,
    errorObj: {},
    isLoading: false,
    isSessionClear: false,
  });

  useEffect(()=>{
    const data = JSON.parse(localStorage.getItem('451')) || {};
        if (Object.keys(data).length) {
            setData({
                isSessionClear: true,
                isError: true,
                errorObj: {
                    ...data
                }
            })

            localStorage.removeItem('451')
        }
  },[])

  const onChangeAction = (e) => {
    const { name, value } = e.target;
    if(value.length===11){
      return false
    }
      setData({ ...data, [name]: value });
    
  };



  const loginHandler = () => {
    setData({...data,isLoading:true})
    LoginAction({login_username:`+91${data.mobileNumber}`,method_of_login:"phone_otp",platform:"my_track"}).then((res)=>{
      fetchMobileNo(res.user_data.phone_number)
      OtpSentAction({login_username:`+91${data.mobileNumber}`,method_of_login:"phone_otp",purpose:"user_login"}).then((res)=>{
        setData({...data,isLoading:false})
        console.log(res)
        history.push(EKYC.OTP)
      })
    }
    ).catch((err)=>{
      setData({...data,isLoading:false,isError:true,errorObj:{...err}})
      
    })
  };

  const loginAction = (e) => {
    if (data.isSessionClear) {
        triggerSimpleAjax(
            LOGOUT_ALL_SESSION ,
            'POST',
            {},
            () => {
                // this.props.history.push(ROUTES.HOME_PAGE_URL);
                loginHandler()
            },
            () => {
              history.push(ROUTES.HOME_PAGE_URL)
            }
        )
        return true
    }
    e && e.preventDefault();
    loginHandler()
}

  return (
    <PageDescriptionStyle>
      <SectionTitle subText={EKYC_LOGIN_TITLE} />
      <FormStyle>
        <TextBox
          labelName="Mobile No"
          placeholder="Enter Mobile Number"
          check="ekycInput"
          name={"mobileNumber"}
          value={data.mobileNumber}
          type="number"
          onChange={onChangeAction}
          error={data.isError}
          errorMessage={data.errorObj?.login_username}
        />

        <Button type="submit" onClick={loginAction} isLoading={data.isLoading}>
          {data.isSessionClear ? "Logout All Session" : "Login"}
        </Button>
      </FormStyle>
    </PageDescriptionStyle>
  );
};

export default EkycLogin;
