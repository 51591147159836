import React from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import LeftSideContainer from "../../login/components/LeftSideContainer";
import { RightContainerStyle } from "../../login/components/RightSideContainer";
import ROUTES, { EKYC } from "../../../helpers/constants/routes";
import LoginLogo from "../../../components/LoginLogo";
import Footer from "../../login/components/Footer";
import LoginPage from "./EkycLogin";
import VerifyOTPPage from "./VerifyOtp";
import themeSizes from "../../../theme/themeSizes";

export const LoginStyle = styled.div`
  display: flex;
  section {
    width: 100%;
  }
  & > section:first-child {
    background: linear-gradient(0deg, #4770c1, #4770c1);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  height: 100vh;

  @media ${themeSizes.mobile} {
    flex-flow: column;
  }
`;

export const Login = () => {
  return (
    <LoginStyle>
      <LeftSideContainer check="EKYC" />
      <RightContainerStyle>
        <LoginLogo />
        <Switch>
          <Route path={EKYC.LOGIN} exact component={LoginPage} />
          <Route path={EKYC.OTP} exact component={VerifyOTPPage} />
        </Switch>
        <Footer />
      </RightContainerStyle>
    </LoginStyle>
  );
};

export default Login;
