import React from 'react';
import styled,{ css } from 'styled-components';
import { cinnabar300 } from '../../../theme/colors';
import themeSizes from '../../../theme/themeSizes';
import { baseCaption } from '../../../theme/typography';

const RowStyle = styled.div`
    min-height:45px;
    margin: ${props => props.margin || '22px 0'};
    display:flex;
    flex-wrap: wrap;
    ${baseCaption}
    ${props => props.noFlexWrap && css`
        flex-wrap:nowrap;
        align-items:${props => props.labelAlign || 'center'};

    `}
    &>span{
        width:13%;
        padding-top:12px;
        ${props => props.noFlexWrap && css`
            padding-top:${props => props.labelAlign?'12px':0};
        `}
        ${props => props.isAsterisk && css`
            &:after{
                content:' *';
                color: ${cinnabar300};
            }
        `};
        ${props => props.labelColor && css`
            color: ${props.labelColor};
        `};
        @media ${themeSizes.tablet} {
            width:100% !important;
            margin-bottom:20px;
          }
    };
    @media ${themeSizes.tablet} {
        justify-content:flex-start !important;
      }
`;

export const FormRow = ({
name='',
asterisk,
rightElement='',
leftSideWidth = 'auto',
margin,
labelColor,
noFlexWrap=false,
labelAlign,
customStyles,isMandatory,
labelMargin
}) => {
    return (
        <RowStyle 
            margin={margin} 
            isAsterisk={asterisk} 
            labelColor={labelColor} 
            noFlexWrap={noFlexWrap}
            labelAlign={labelAlign}
            style={{...customStyles}}
        >
            <span style={{width: leftSideWidth,margin:labelMargin}}>{name} {isMandatory && <span style={{color:'#E23A3A'}}>*</span>}</span>
            {rightElement}
        </RowStyle>
    )
}