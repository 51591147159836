import React, { Fragment, useEffect } from "react";
import ErrorBoundary from "./components/ErrorBoundary";
import { Route, Switch, withRouter } from "react-router-dom";
import {
  LoginLoadable,
  DashBoardLoadable,
  RetailersLoginLoadable,
  RetailersDashboardLoadable,
  Form16DashboardLoadable,
} from "./helpers/loadableComponentHelper";
import {OutletAddLoadable,OutletDetailLoadable,OutletSurveyLoadable,EkycLoginLoadable, EkycDashboardLoadable, PendingLoadable, RejectedLoadable, CompletedLoadable, RsaFormLoadable, RsaDetailLoadable, AddOnFlyLoadable} from "./helpers/ekyc"
import ROUTES, {
  DASHBOARDROUTES,
  RETAILERS,
  EKYC,
  GAME,
} from "./helpers/constants/routes";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GlobalStyle from "./global-styles";
import getUserInfo from "./actions/getUserInfo";
import Notification from "./components/Notification";
import PrivateRoute from "./helpers/utils/privateRoute";
import initFWHelp from "./helpers/utils/initFWHelp";
import OutletDetails from "./pages/dashboard/outlets/outlets-details";
import KycModalImages from "./pages/dashboard/client-portal/client-payout/KycModalImages";
import IFSCVerification from "./pages/IfscVerification";
import FieldAppDump from "./pages/dashboard/field_app_dump/FieldAppDump";
import EkycLogin from "./pages/ekyc/login/index";
import { GameDashboardLoadable, GameListingAllLoadable, GameListingLoadable, GameReportLoadable } from "./helpers/game";
import useStore from "./appZustand/store";
import OutletFieldRecovery from "./pages/dashboard/field_outlet_recovery/OutletFieldRecovery";

const App = ({ getUserInfo, history }) => {

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // http://retailscanportal.com/something/adsad/?open_store=True

    console.log("navigator",navigator.userAgent)

    let params = history.location.search === "?open_store=True"

    if (/android/i.test(userAgent) && params) {

       window.location.href =  "https://play.google.com/store/apps/details?id=com.retailscan.ezeepay"
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && params) {
      window.location.href =  "https://play.google.com/store/apps/details?id=com.retailscan.ezeepay"
    }

    return "unknown";
}


const {fetchUserDetails}=useStore((state)=>state)

  useEffect(() => {

    let params = history.location.search === "?open_store=True"

    if(params){

      getMobileOperatingSystem()

    }else{


      const userName = localStorage.getItem("userName");

    if (userName) {
      getUserInfo(userName).then((data) => {

        fetchUserDetails(data?.user_details)

        if (!data?.loggedIn) {
          initFWHelp();
        }
      });
    } else {
      initFWHelp();
      if (history.location.pathname.includes("retailers")) {
        history.push(ROUTES.RETAILERS_LOGIN);
      } else if(history.location.pathname.includes("ekyc")){
        history.push(EKYC.LOGIN);
      } else if(history.location.pathname.includes("game")){
        history.push(ROUTES.LOGIN);
      } else{
        history.push(ROUTES.LOGIN);
      }
    }

    }

    
    
    
  }, []);
  return (
    <Fragment>
      <GlobalStyle />
      <ErrorBoundary>
        <Switch>
          <Route
            path={[
              ROUTES.HOME_PAGE_URL,
              ROUTES.LOGIN,
              ROUTES.FORGOT_PASSWORD,
              ROUTES.VERIFY_OTP,
              ROUTES.RESET_PASSWORD,
            ]}
            exact
          >
            <LoginLoadable />
          </Route>
          <Route
            path={[
              ROUTES.RETAILERS_LOGIN,
              ROUTES.RETAILERS_CHOOSE_MOBILE_NUMBER,
              ROUTES.RETAILERS_ENTER_MOBILE_NUMBER,
              ROUTES.RETAILERS_OTP,
              ROUTES.RETAILERS_SUCCESS,
            ]}
            exact
          >
            <RetailersLoginLoadable />
          </Route>
          <Route
            path={ROUTES.DASHBOARD}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.META}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_USERS_ROLE}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ORG_LIST}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ORG_CREATION}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ORG_UPDATE}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ORG_VIEW}
            exact={true}
            component={DashBoardLoadable}
          />

          {/* Regions */}
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_REGIONS_LIST}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_REGIONS_ADD}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_REGIONS_EDIT}
            exact={true}
            component={DashBoardLoadable}
          />

          {/* Zone */}
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ZONES_LIST}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ZONES_ADD}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ORG_ADD_OFFER}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ORG_EDIT_OFFER}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ZONES_EDIT}
            exact={true}
            component={DashBoardLoadable}
          />

          {/* Territory */}
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_TERRITORIES_LIST}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_TERRITORIES_ADD}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_TERRITORIES_EDIT}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ORG_OFFER}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_USER_LIST}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ROLES_LIST}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ROLE_VIEW}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ROLE_CREATION}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_ROLE_UPDATE}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_USER_CREATION}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_USER_UPDATE}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_USER_VIEW}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_USER_PROFILE}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_BULK_UPLOAD_USERS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_BULK_UPLOAD_REGIONS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_BULK_UPLOAD_ZONES}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ORGANIZATION_BULK_UPLOAD_TERRITORIES}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.META_STATE_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.META_CITY_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.META_STATE_ADD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.META_STATE_EDIT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.META_CITY_ADD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.META_CITY_EDIT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={`${DASHBOARDROUTES.META_CITY_LIST}/bulk-upload`}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.META_CITY_MERGE}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PROJECTS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PROJECT_NOTIFICATIONS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PROJECTS_CREATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PROJECTS_UPDATE}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_CREATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_UPDATE}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_VIEW_BULK_UPLOAD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.OUTLET_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.OUTLET_DETAILS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DAHSBOARD_INDIVIDUAL_OUTLET_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.QUESTION_MANAGEMENT_LANDING}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SKU_QUESTION_MANAGEMENT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.QUESTION_MANAGEMENT_LANDING_UPLOAD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SKU_QUESTION_MANAGEMENT_LANDING_UPLOAD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.OUTLET_MANAGEMENT_LANDING}
            exact
            component={DashBoardLoadable}
          />

   <Route path={DASHBOARDROUTES.FIELD_OUTLET_RECOVERY} exact component={DashBoardLoadable} />
          <Route
            path={DASHBOARDROUTES.OUTLET_MANAGEMENT_OUTLET_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_OUTLET_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_OUTLET_VIEW_DETAILS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.CLIENT_DASHBOARD_SUB_PROJECTS_PAYOUT_TABLE}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={
              DASHBOARDROUTES.CLIENT_DASHBOARD_SUB_PROJECTS_INDIVIDUAL_PAYOUT
            }
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={
              DASHBOARDROUTES.CLIENT_DASHBOARD_SUB_PROJECTS_PAYOUT_TABLE_IMAGES
            }
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={
              DASHBOARDROUTES.CLIENT_DASHBOARD_SUB_PROJECTS_PAYOUT_CYCLE_VIEW
            }
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.CLIENT_DASHBOARD_OUTLET_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.CLIENT_DASHBOARD_OUTLET_LIST_DETAILS_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.CLIENT_DASHBOARD_SUB_PROJECTS_CYCLE_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_CYCLE_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_CYCLE_VIEW_OUTLET_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_CYCLE_VIEW_OUTLET_VIEW_DETAILS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_KYC_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_KYC_VIEW_OUTLET_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PROGRAM_MANAGEMENT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PROGRAM_MANAGEMENT_ADD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PROGRAM_MANAGEMENT_EDIT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PANEL_MANAGEMENT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ACCOUNT_VERIFICATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.ICICI_ACCOUNT_VERIFICATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.IFSC_VERIFICATION}
            exact={true}
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PAN_VERIFICATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.MY_DOWNLOADS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.TDS_CALCULATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.TDS_REPORTS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.UNICORN_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.UNICORN_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.RETAILERS_INVOICE_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.FIELD_APP_DUMP_BULK_UPLOAD}
            exact
            component={DashBoardLoadable}
          />

            <Route
            path={DASHBOARDROUTES.FORM_16_CSV}
            exact
            component={DashBoardLoadable}
          />
            <Route
            path={DASHBOARDROUTES.RETAILER_REPORT_ALL}
            exact
            component={DashBoardLoadable}
          />
              <Route
            path={DASHBOARDROUTES.RETAILER_REPORT_PDF}
            exact
            component={DashBoardLoadable}
          />    <Route
          path={DASHBOARDROUTES.RETAILER_REPORT_CSV}
          exact
          component={DashBoardLoadable}
        />    <Route
        path={DASHBOARDROUTES.FORM_16_PDF}
        exact
        component={DashBoardLoadable}
      />

          <Route
            path={DASHBOARDROUTES.PANEL_MANAGEMENT_ADD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PANEL_MANAGEMENT_EDIT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PANEL_MANAGEMENT_UPLOAD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PANEL_MANAGEMENT_OUTLET_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.PANEL_MANAGEMENT_OUTLET_LIST_DETALS_VIEW}
            exact
            component={DashBoardLoadable}
          />

          <Route
            path={DASHBOARDROUTES.SUB_PROJECTS_CYCLE_OUTLET_VIEW_DETAILS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.CLIENT_DASHBOARD}
            exact
            component={DashBoardLoadable}
          />

          <Route
            path={DASHBOARDROUTES.UPLOAD_SPOC}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.UPLOAD_PAYOUT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.UPLOAD_CLIENT_PLOT_PAYOUT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DAHSBOARD_VIEW_ALL}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DAHSBOARD_CALLING_MANAGE_ALLOCATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DAHSBOARD_CALLING_MANAGE_ALLOCATION_EDIT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DAHSBOARD_QC_MANAGE_ALLOCATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DAHSBOARD_QC_MANAGE_ALLOCATION_EDIT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DAHSBOARD_IFTU_MANAGE_ALLOCATION}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.DAHSBOARD_IFTU_MANAGE_ALLOCATION_EDIT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.FORM16_UPLOAD}
            exact
            component={DashBoardLoadable}
          />
           <Route
            path={DASHBOARDROUTES.RETAILER_REPORT_ALL}
            exact
            component={DashBoardLoadable}
          />
           <Route
            path={DASHBOARDROUTES.RETAILER_REPORT_PDF}
            exact
            component={DashBoardLoadable}
          />
           <Route
            path={DASHBOARDROUTES.RETAILER_REPORT_CSV}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.FORM16_PAN_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.FORM16_STORE_DETAIL}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.REPORTS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.CLIENT_REPORTS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.CLIENT_REPORTS_KYC_IMAGES}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.CLIENT_REPORTS_OUTLET}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.RETAIL_AUDIT_REPORT}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.RETAIL_AUDIT_REPORT_OUTLET_LIST}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.RETAIL_AUDIT_REPORT_IMAGES}
            exact
            component={DashBoardLoadable}
          />
          {/** Retailers */}
          <Route
            path={DASHBOARDROUTES.MESSAGING}
            exact
            component={DashBoardLoadable}
          />

          <Route
            path={DASHBOARDROUTES.DETAILS_VIEW}
            exact
            component={DashBoardLoadable}
          />

          <Route
            path={DASHBOARDROUTES.SUMMARY_VIEW}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.LOGIC_SCORE}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.LOGIC_SCORE_SCORING_PARAMETERS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.LOGIC_SCORE_CALCULATED_PARAMETERS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.LOGIC_SCORE_BENCHMARKS}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={DASHBOARDROUTES.LOGIC_SCORE_BENCHMARKS_BULK_UPLOAD}
            exact
            component={DashBoardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_DASHBOARD}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_PROFILE}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_EARNINGS}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_PARTICULAR_ORG_EARNINGS}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_GET_NOTIFICATIONS}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_OUTLETS_INFO}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_OUTLETS}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_EARNINGS}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_ALL_INVOICES}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_OFFERS}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_OFFERS_INFO}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_DASHBOARD_PDF}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_ALL_INVOICES_PDF}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_PARTICULAR_ORG_EARNINGS_PDF}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_OUTLETS_EDIT}
            exact={true}
            component={RetailersDashboardLoadable}
          />
          <Route
            path={RETAILERS.RETAILERS_FORM16_LIST}
            exact={true}
            component={RetailersDashboardLoadable}
          />

          <Route
            path={[
              EKYC.LOGIN,
              EKYC.OTP
            ]}
            exact
          >
            <EkycLoginLoadable />
          </Route>
          <Route path={EKYC.DASHBOARD} exact component={EkycDashboardLoadable} />
          <Route path={EKYC.OUTLET_Add} exact component={OutletAddLoadable} />
          <Route path={EKYC.OUTLET_DETAIL} exact component={OutletDetailLoadable} />
          <Route path={EKYC.OUTLET_QUESTION_LISTING} exact component={OutletSurveyLoadable} />
          <Route path={EKYC.PENDING_LISTING} exact component={PendingLoadable} />
          <Route path={EKYC.REJECTED_LISTING} exact component={RejectedLoadable} />
          <Route path={EKYC.COMPLETED_LISTING} exact component={CompletedLoadable} />
          <Route path={EKYC.RSA_FORM} exact component={RsaFormLoadable} />
          <Route path={EKYC.RSA_DETAILS} exact component={RsaDetailLoadable} />
          <Route path={EKYC.ADD_ON_FLY} exact component={AddOnFlyLoadable} />

          {/* Game Route */}

          <Route path={GAME.DASHBOARD} exact component={GameDashboardLoadable} />
          <Route path={GAME.LISTING()} exact component={GameListingLoadable} />
          <Route path={GAME.LISTING_ALL()} exact component={GameListingAllLoadable} />
          <Route path={GAME.REPORT()} exact component={GameReportLoadable} />

        </Switch>
        <Notification />
      </ErrorBoundary>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(App));
