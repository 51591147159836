export const USER_DETAILS = 'USER_DETAILS';
export const META_DATA = 'META_DATA';
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';
export const STORE_USERS_DATA = 'STORE_USERS_DATA';
export const STORE_ORG_LIST = 'STORE_ORG_LIST';
export const STORE_ORG_DATA = 'STORE_ORG_DATA';

export const STORE_ORG_REGION_LIST = 'STORE_ORG_REGION_LIST';
export const STORE_ORG_REGION_DATA = 'STORE_ORG_REGION_DATA';  
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const NOTIFICATION = 'NOTIFICATION';

export const SUB_PROJECT_QUERY_DATA_OUTLET = 'SUB_PROJECT_QUERYDATA_OUTLET';

export const GET_PAN_LIST = 'GET_PAN_LIST';
//WhatsApp constants
export const GET_WHATSAPP_DETAILS = 'GET_WHATSAPP_DETAILS';
export const GET_WHATSAPP_SUMMARY = 'GET_WHATSAPP_SUMMARY';
