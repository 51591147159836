import styled, { css } from 'styled-components';
import { baseCaption, defaultFont } from '../../theme/typography';
import { blue300, cinnabar300, black600, selago, white } from '../../theme/colors';
import { bold } from '../../theme/typeface';
import { Link } from 'react-router-dom';
import themeSizes from "../../theme/themeSizes";

export const DashboardWrapper = styled.div`
    position:relative;
    display:flex;
`;

export const SideNavStyle = styled.aside`
  min-width: ${({ expand }) => expand ? '14%' : '6%'};
  background-color: #FFFFFF;
  position: sticky;
  cursor: pointer;
  top: 0;
  height: 100%;
  transition: min-width 0.2s;

  @media ${themeSizes.mobile}{
    min-width: ${({ expand }) => expand ? '12%' : '0'};
  }
  
  img{
    margin-top:18px;
    margin-left: 24px;
    transition: all 0.2s;
  }
 
    ul{
        top:60px;
        width:100%;
        padding:0;
        list-style:none;
        position: absolute;
        height: calc(100vh - 110px);
        overflow-y:auto;
        padding-right:5px;

        &::-webkit-scrollbar {
            width: 0.6em;
          }
           
          &::-webkit-scrollbar-track {
          }
           
          &::-webkit-scrollbar-thumb {
            background-color: #DEDEDE;
            // outline: 1px solid slategrey;
            border-radius:20px;
            margin-left:10px;
          }
  
        li{
          height: 50px;
          padding-left:28px;
          padding-right: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          
          @media ${themeSizes.mobile}{
            padding-left: 8px;
          }
          ${baseCaption}
          svg{
              position: relative;
              float: left;
          }
          span{
              opacity:${({ expand }) => expand ? '1' : '0'};
              padding-left:8px;
              display: grid;
              transition:opacity 0.2s;
          }
          &.active{
              svg{
                  color:${blue300};    
              }
              
              ${props => props.expand && css`
                  border-right: 4px solid ${blue300};
              `}
          }
      }
      }

    
`;

export const ContentWrapper = styled.section`
    min-width:${({ expand }) => expand ? '86%' : '94%'};
    max-height:100%;
    background: ${selago};
    position:relative;
    transition:all 0.2s;
    .sticky-inner-wrapper {
        z-index: 5;
    }
`;

export const HorizontalBar = styled.div`
    background:  ${white};
    box-shadow: 12px 4px 16px rgba(91, 127, 220, 0.2);
    max-height: 52px;
    padding:12px;
    position:sticky;
    min-width:92%;
    display:flex;
    justify-content:space-between;
    span{
        display:inline-block;
        svg{
            font-size:28px;
            left:6px;
            margin-top:5px;
        }     
    } 
    div{
        span{
            ${defaultFont}
            color:${cinnabar300};
            cursor: pointer;
            vertical-align:super;
            margin-right:12px;
        }
       
        svg{
            margin-top:5px;
        } 
    }
`;

export const ContentStyle = styled.div`
    min-height: 100vh;
    display:flex;
    padding: 0 24px;
    &>div{
        flex:1;
    }
`;

export const TitleStyle = styled.div`
    ${defaultFont}
    font-weight: ${bold};
    margin: ${({margin})=> margin || 0};
`;


export const Button = styled(Link)`
    border: 1px solid #212323;
    border-radius: 2px;
    background: ${props => props.background || '#212323'} ;
    color: ${props => props.color || '#FFFFFF'} ;
    font-size: 16px;
    font-weight: 300;
    padding:  ${props => props.padding || '10px'} ;
    margin-left: ${({ align }) => align === 'right' && `12px`};
    margin-right: ${({ align }) => align === 'left' && `12px`};

    svg{
        vertical-align: -7px;
    }
`
export const UploadButtonBlock = styled.span`
    position: ${props => props.position || 'static'} ;
    right: ${props => props.right || '0px'} ;
    border: 1px solid transparent;
    border-radius: 2px;
    background: ${props => props.background || '#212323'} ;
    color: ${props => props.color || '#FFFFFF'} ;
    font-size: 16px;
    font-weight: 300;
    padding: ${props => props.padding || '6px 10px'};
    border:1px solid ${props => props.borderColor || 'transparent'};
    margin-left:  ${({ align }) => align === 'right' && `12px` || align === 'to right' && `150px` || align === 'right end' && `650px`};
    margin-right: ${({ align }) => align === 'left' && `12px`};
    cursor:pointer;
    svg{
        vertical-align: -7px;
        margin-right:8px;
    }
    ${props => props.isDisabled && css`
        cursor:not-allowed;
        opacity: 0.8;
    `}
`

export const InvertButton = styled(Button)`
color: #212323;
background:  #FFFFFF;
border: 1px solid #212323;
margin-left: ${({ align }) => align === 'right' && `12px`};
margin-right: ${({ align }) => align === 'left' && `12px`};
`
export const TitleContainer = styled(TitleStyle)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`

export const ButtonContainer = styled.div`
    display: ${props => props.display || 'flex'};
    margin-top: ${props => props.marginTop || '0px'};
    float: ${props => props.float || 'auto'}
`


