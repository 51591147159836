import React, { useState } from 'react';
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import InnerImageZoom from "react-inner-image-zoom";

const SingleImage = ({index, content, getValue, image, rotate, rotateLeft, rotateRight, onClickImage = () => {}, imageDimension, imageZoomState}) => {

  const rotateIconStyles = {
    marginTop: '30px',
  }
  return (
    <div key={index}>
      {content ? <p>{content}</p> : null}
      <div style={{
        transform: `rotate(${rotate}deg)`,
        maxWidth: "350px",
        minHeight: imageDimension,
        margin: "auto"
      }}>
        <InnerImageZoom src={getValue(image)?.image} zoomPreload />
      </div>
      <div style={{
        zIndex: 2000,
        margin: '20px 0'
      }}>
        <RotateLeftIcon onClick={rotateLeft} />
        <RotateRightIcon onClick={rotateRight} />
      </div>
    </div>
  )
}

export default SingleImage
