import styled, { css } from 'styled-components';
import { green300, white, blue300 } from '../theme/colors';
import { baseCaption, microCaption } from '../theme/typography';
import { xsBold } from '../theme/typeface';

export const StatusTagStyle = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: relative;
    min-width: 95px;
    min-height: 25px;
    ${baseCaption};
    font-weight: ${xsBold};
    padding: 0px 12px 0 18px;
    text-transform: uppercase;
    background: ${props =>props.color || green300};
    border-radius: 30px;
    color: ${white};
    box-sizing:border-box;
  

    ${props => props.size === 'small' && css`
        min-width:60px;
        height: 18px;
        ${microCaption.regularUpperCase}
        font-weight: ${xsBold};
    `}

    ${props => props.bordered &&
        css`
            background-color: transparent;
            color: ${props => props.borderColor || blue300};
            border: 1px solid ${props => props.borderColor || blue300};
        `
    }
    
    ${props => props.withOutIcon ? 
        css`
            padding-left: 12px;
        `
        :css`
            :before{
                content:'';
                position:absolute;
                border-radius:100%;
                background: ${white};
                
                width: 8px;
                height: 8px;
                left: 5px;
                top: 9px;

                ${props => props.size === 'small' && css`
                    width: 6px;
                    height: 6px;
                    left: 8px;
                    top: 9px;
                `}
                
                ${props => props.bordered && 
                    css`
                        background: ${props.borderColor || blue300}
                    `
                }
            }
        `
    }
`;
